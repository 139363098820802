@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #000000;
  font-family: "Montserrat", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("./assets/icons/cal.png");
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 10px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
